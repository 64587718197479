html {
  height: 100%;
}
body {
  height: 100%;
  overflow: hidden;
}

@media screen {
  body {
    background: #e6e6e6 !important;
  }
  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
